body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: var(--background);
}

* {
  color: var(--text);
}

:root {
  --primary: #5d45bf;
  --primary-background: #eeebf8;
  --background: #ffffff;
  --text: #11141c;
}
