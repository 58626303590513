/* Add your global styles here */

#root {
  height: 100vh;
  background-color: var(--primary-background);
  display: flex;
  justify-content: center;
}

.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-text {
  flex: 1;
  padding: 20px;
  text-align: center;
  color: var(--primary);
}

.portfolio-text h1 {
  font-size: 2em;
}

.highlight-text {
  font-weight: bold;
  color: var(--primary)
}

.portfolio-text p {
  padding-top: 20px;
}

.contact-button {
  color: var(--primary);
  background-color: var(--primary-background);
  padding: 5px 20px;
  border: 2px solid var(--primary);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: var(--primary);
  color: var(--primary-background);
}

.portfolio-image {
  flex: 1;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 20px;
  position: relative; /* Add relative positioning */
}

.image-shape {
  width: 420px; /* Adjust the size of the image shape */
  height: 420px; /* Adjust the size of the image shape */
  background-size: contain; /* Ensure the image fits within the shape */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center horizontally within image-shape */
  align-items: center; /* Center vertically within image-shape */
  z-index: 0; /* Set a lower z-index to keep it in the background */
}



.image {
  max-width: 100%;
  max-height: 100%;
  position: relative; /* Add relative positioning */
  z-index: 1; /* Ensure the image appears on top of the shape */
}

@media (max-width: 768px) {
  #root {
    display: block;
  }
  .portfolio {
    flex-direction: column-reverse; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: flex-start; /* Align items to the top */
    height: auto; /* Allow container to adjust to content */
  }
  
  .portfolio-text {
    order: 2; /* Move text below image */
  }

  .portfolio-image {
    order: 1; /* Move image above text */
  }
}